import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Navbar from '../NavBar';
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import "./style.css";
import CarouselHome from "../CarouselHome";

type HomeProps = {
  handleRedirectGoogleMaps?: () => void;
}

export default function Home(props: HomeProps) {
  document.title = 'Portal da PGM'
  return (
    <>
      <div id="home" className="home-container">
        <div className="container">
          <Navbar />
          <CarouselHome handleRedirectGoogleMaps={props.handleRedirectGoogleMaps} />
          <div className="text-center pt-5">
            <LinkScroll className="h3 icon-arrow-down" smooth={true} duration={500} to="atendimento">
              <FontAwesomeIcon icon={faArrowDown} />
            </LinkScroll>
          </div>
        </div>
      </div>
    </>
  );
}
