import React, { useState } from "react";
import "./style.css"
import LogoPrefeitura from "../../../assets/images/landingPage/logo-prefeitura-PGM.svg";
import iconWhatsapp from "../../../assets/images/icon/whatsapp.svg"
import ModalBalcaoVirtual from "../../ModalBalcaoVirtual";

type SectionProps = {
  handleRedirectGoogleMaps?: () => void;
}

export default function Section7(props: SectionProps) {
  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }


  return (
    <>
      <div className="section7">
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-4">
              <div className="">
                <img className="img-fluid" src={LogoPrefeitura} alt="" />
              </div>
            </div>
            <div className="section-info col-lg-8 col-sm-12">
              <div className="row">
                <div className="col-sm-4">
                  <p className="title-item-footer">Endereço</p>
                  <p className="description-item-footer" onClick={props.handleRedirectGoogleMaps}>
                    Rua Israel Bezerra, 570, <br/> Dionísio Torres.
                    <br/>CEP 60135-460<br/>
                    Fortaleza - CE. Brasil.
                  </p>
                </div>
                <div className="col-sm-4">
                  <p className="title-item-footer">Horário de funcionamento</p>
                  <p className="description-item-footer">
                    De Seg a Sex <br/>
                    das 08:00 às 17:00
                  </p>
                  <p className="title-item-footer">Dívida Ativa</p>
                  <p className="description-item-footer">
                    De Seg a Sex <br/>

                    das 08:30 às 17:00 <br/>
                    Presencial agendado <br />
                    WhatsApp (85) 98419-2045 <br /><br />

                    08:30 às 17:30 <br/>
                    Balcão virtual e telefones
                  </p>
                </div>
                <div className="col-sm-4">
                  <p className="title-item-footer">Contato </p>
                  <p className="description-item-footer">Atendimento da Dívida Ativa (Procuradoria da Fazenda)
                                                          <br />(85) 98419-2045<br />(85) 98419-2042</p><br />
                  <p className="description-item-footer">Precisa de ajuda? Acesse<br /> nosso
                    <button type="button" className="btn-balcao-virtual" onClick={handleShowBalcaoVirtual}>
                      Balcão Virtual
                    </button>
                    e fale com um de nossos atendentes
                  </p>
                  <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-pgm py-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h6 className="text-pgm text-center">
                Desenvolvido pela Coordenadoria de Tecnologia, Governança e Inovação da Procuradoria Geral do Município de Fortaleza.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
