import React from "react";
import WhatsappIcon from "../../assets/images/icon/whatsapp.svg";
import styles from "./style.module.scss";

export function  FloatingIconWhatsapp() {

  const handleRedirectWhatsapp = () => {
    window.open('https://api.whatsapp.com/send/?phone=5585992269903', "_blank")
  }

  return (
    <div className={styles.content} onClick={handleRedirectWhatsapp}>
      <img src={WhatsappIcon} alt="whatsapp icon" />
      <span>Whatsapp</span>
    </div>
  );
}
