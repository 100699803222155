import { JitsiMeeting } from '@jitsi/react-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ConsultDebtsVirtualDesk from '../../components/ConsultDebtsVirtualDesk';
import "./style.scss";
import { Header } from '../../components/Header';
import { Instructions } from './Instructions';
import ContributorGeneralInfos from '../../components/ContributorGeneralInfos';
import { getUser } from '../../services/AuthService';
import BalcaoVirtualService from '../../services/BalcaoVirtualService';
import { Loading } from './Loading';
import ConsultarService from '../../services/ConsultarService';
import { Rating } from './Rating';
import ring from '../../assets/sound/ring.mp3';
import { Form } from './Form';
import moment from 'moment';
import Modal from '../../components/Modal';
import { isMobileDevice } from '../../helpers/Utils';
import Show from '../../components/Show';

const audio = new Audio(ring);
audio.loop = true;

type VideoCallParams = {
  id: string;
  balcaoId: string;
};

export type callType = 'attendant' | 'contributor' | undefined;

export function VideoCall() {
  const {balcaoId, id} = useParams<VideoCallParams>();

  const [contributorData, setContributorData] = useState<Array<any>>([]);
  const [type, setType] = useState<callType>(undefined);
  const [datetime, setDatetime] = useState<Date | undefined>();
  const [documentToSend, setDocumentToSend] = useState(localStorage.getItem('document'));
  const [documents, setDocuments] = useState(localStorage.getItem('documents'));
  const [attendantName, setAttendantName] = useState('');
  const [attendantId, setAttendantId] = useState<any>();
  const [isMobile, setIsMobile] = useState<boolean | undefined>(false);
  const [rate, setRate] = useState<boolean>(false);
  const [timer, setTimer] = useState('');
  const [onCall, setOnCall] = useState(false);
  const [attendanceName, setAttendanceName] = useState('');
  const [completeData, setCompleteData] = useState();
  const [outScreenAlert, setOutScreenAlert] = useState<boolean>(false);
  const [jwtToken, setJwtToken] = useState<undefined | string>();
  const [seconds, setSeconds] = useState(20);
  const [disabled, setDisabled] = useState(true);
  const [timeDiffInSeconds, setTimeDiffInSeconds] = useState<number | null>(null);
  const [status, setStatus] = useState<'stable' | 'unstable' | 'disconnected' | undefined>();

  const { pathname } = useLocation();

  const headerPgm = document.querySelectorAll("#header-fortaleza");
  const rootDiv = document.getElementById("root");
  const videoCallPath = pathname.split("/")[1];

  const playRing = () => {
    audio.play();
  }

  useEffect(() => {
    if (documentToSend || rate) {
      audio.pause();
    }
  }, [documentToSend, rate]);

  const getContributorDatas = (data?: Array<any>) => {
    setContributorData([]);
    if(data){
      setContributorData(data)
    }
  }

  const resetStyles = () => {
    if (videoCallPath !== undefined && rootDiv !== null) {
      headerPgm.forEach((div) => div.classList.remove("disabledHeader"));
      rootDiv.classList.remove("resetMargin");
    }
  };

  useEffect(() => {
    const data = getUser();
    const urlParams = new URLSearchParams(window.location.search);
    const ratingParam = urlParams.get('rating');

    if(ratingParam) {
      setRate(true);
    }

    if (videoCallPath !== undefined && rootDiv !== null) {
      headerPgm.forEach((div) => div.classList.add("disabledHeader"));
      rootDiv.classList.add("resetMargin");
    }

    BalcaoVirtualService.balcaoPorID(balcaoId)
    .then((res) => {
        const contributorDocument = JSON.parse(res.contributor_document as string);
        setJwtToken(res.jwt_token);
        const contributorsDocuments = JSON.parse(documents as string)
        setAttendantId(res.attendant_id);
        if (data) {
          const user = JSON.parse(data);
          if (res.attendant_id === user.id) {
            setAttendantId(user.id);
            setType('attendant');
          } else {
            window.location.href = '/';
          }
        } else {
          ConsultarService.attendant(res.attendant_id)
            .then((attendantData) => {
              if(attendantData.name){
                setAttendantName(attendantData.name);
              }
            })
            .catch(() => { window.location.href = '/' });
          if (contributorDocument && contributorsDocuments && contributorDocument[0] === contributorsDocuments[0]) {
            setType('contributor');
          } else {
            if (isMobileDevice()) {
              setType('contributor');
            } else {
              window.location.href = '/';
            }
          }
        }
      })
      .catch(() => { window.location.href = '/' });
  }, []);

  useEffect(() => {
    console.log(timeDiffInSeconds)
    if (timeDiffInSeconds === null) return;

    if (timeDiffInSeconds >= 10 && timeDiffInSeconds <= 19) {
      setStatus('unstable');
    } else if (timeDiffInSeconds >= 20 && timeDiffInSeconds <= 29) {
      setStatus('disconnected');
    } else if (timeDiffInSeconds < 10) {
      setStatus('stable');
    } else if (timeDiffInSeconds > 29) {
      setRate(true);
    } else {
      setStatus(undefined);
    }
  }, [timeDiffInSeconds]);

  const checkIfOnline = (date1: string, date2: string) => {
    const d1 = moment(date1);
    const d2 = moment(date2);

    const diffInSeconds = d1.diff(d2, 'seconds');
    setTimeDiffInSeconds(diffInSeconds);
  };

  const updateLastSeen = (rating: boolean) => {
    console.log(rating);
    if (!rating) {
      type == 'attendant'
        ? BalcaoVirtualService.updateAttendanceLastSeen(Number(balcaoId)).then((resp: any) => {
            if (resp.data.status == 'unavailable') {
              setRate(true);
            }
            checkIfOnline(resp.data.attendance_last_seen, resp.data.contributor_last_seen)
          })
        : BalcaoVirtualService.updateContributorLastSeen(Number(balcaoId)).then((resp: any) => {
            checkIfOnline(resp.data.contributor_last_seen, resp.data.attendance_last_seen)
          })
    }
  }

  useEffect(() => {
    if (!rate && onCall) {
      setTimeout(() => {
        formatTimer();
      }, 1000);
    }
  }, [timer, datetime, onCall]);

  useEffect(() => {
    if (onCall && type !== undefined) {
      const intervalId = setInterval(() => {
        if (!rate) {
          updateLastSeen(rate);
        } else {
          clearInterval(intervalId);
        }
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [onCall, rate]);

  useEffect(() => {
    if (seconds > 0 && outScreenAlert) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    if (seconds === 0) {
      setDisabled(false);
    }

    if (!outScreenAlert) {
      setSeconds(20);
      setDisabled(true);
    }
  }, [seconds, outScreenAlert]);

  useEffect(() => {
    if (type == 'contributor') {
      const handleMouseLeave = (event: globalThis.MouseEvent) => {
        if (event.clientY <= 0) {
          setOutScreenAlert(true);
        }
      };

      window.addEventListener('mouseout', handleMouseLeave);

      return () => {
        window.removeEventListener('mouseout', handleMouseLeave);
      };
    }
  }, [type])

  const formatTimer = () => {
    let startTime = sessionStorage.getItem('startTime');
    if (!startTime) {
      startTime = new Date().toISOString();
      sessionStorage.setItem('startTime', startTime);
    }

    const start = moment(startTime);
    const now = moment();
    const diff = moment.duration(now.diff(start));
    setTimer(moment.utc(diff.asMilliseconds()).format('HH:mm:ss'));
  };

  const handleFinish = () => {
    if (type === 'attendant') {
      return <Form
        attendantId={attendantId}
        resetStyles={resetStyles}
        balcaoId={balcaoId}
        contributorDocument={documentToSend}
        contributorData={contributorData}
        contributorCompleteData={completeData}
        attendanceName={attendanceName}
        />
    }
    return (
      <Rating
        attendantName={attendantName}
        attendantId={attendantId}
        balcaoId={balcaoId}
      />
    )
  }

  const acceptCall = (doc?: string) => {
    if (doc) {
      setDocumentToSend(doc);
      BalcaoVirtualService.setOnAttendant(parseInt(balcaoId));
    }

    BalcaoVirtualService.setOnCall(parseInt(balcaoId))
      .then(() => {
        BalcaoVirtualService.balcaoPorID(balcaoId)
          .then((res) => {
            setOnCall(true);
            setIsMobile(res.is_mobile);
            localStorage.setItem('attendance_id', `${res.attendance_id}`);
          });
      })
  }

  const setUpdatedDocument = (selectedDocument: string) => {
    setDocumentToSend(selectedDocument);
  }

  const setNameAttendance = (name: string) => {
    setAttendanceName(name);
  }

  const completeDataFromForm = (data: any) => {
    setCompleteData(data)
  }


  const renderCall = () => {
    return (
      <div>
        {type && documentToSend && (onCall || type === 'attendant') ? (
          <>
            <div className='videoCallContainer'>
              <div className='videoCallData'>
                <ContributorGeneralInfos
                  userType={type}
                  attendantName={attendantName}
                  contributorData={contributorData}
                  isMobile={isMobile}
                  getUpdatedDocument={setUpdatedDocument}
                  balcaoId={balcaoId}
                  attendance_name={attendanceName}
                  getData={completeDataFromForm}
                  status={status}
                />
              </div>
              <div className='videoCallContent row'>
                <div className='col-sm-4'>
                  <div className='videoCallJitsi'>
                    <JitsiMeeting
                      domain="jitsi.sefin.fortaleza.ce.gov.br"
                      roomName={id}
                      configOverwrite = {{
                        disableThirdPartyRequests: true,
                        disableLocalVideoFlip: true,
                        backgroundAlpha: 0.5,
                        apiLogLevels: ['error'],
                        disableMobilePage: true,
                        disableDeepLinking: true,
                        disableModeratorIndicator: true,
                        disableSelfViewSettings: false,
                      }}
                      jwt={jwtToken}
                      interfaceConfigOverwrite = {{
                        APP_NAME: 'Balcão virtual',
                        VIDEO_LAYOUT_FIT: 'both',
                        MOBILE_APP_PROMO: false,
                        TILE_VIEW_MAX_COLUMNS: 1,
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_BRAND_WATERMARK: false,
                        FILM_STRIP_MAX_HEIGHT: 200,
                        HIDE_INVITE_MORE_HEADER: false,
                        ENABLE_END_CONFERENCE: false,
                        TOOLBAR_BUTTONS: [
                          'microphone', 'camera', 'chat'
                        ]
                      }}
                      getIFrameRef = { (iframeRef) => { iframeRef.style.height = '290px'; } }
                    />
                  </div>
                  <Instructions
                    document={documentToSend}
                    type={type}
                    balcalId={parseInt(balcaoId)}
                  />
                </div>
                <Show if={!isMobileDevice()}>
                  <div className='col-sm-8'>
                    <ConsultDebtsVirtualDesk
                      contributorData={getContributorDatas}
                      userType={type}
                      contributorDocument={documentToSend}
                      attendant_id={attendantId}
                    />
                  </div>
                </Show>
              </div>
            </div>
          </>
        ) : (
          type ? (
            <Loading
              balcaoId={balcaoId}
              onAccept={(doc) => acceptCall(doc)}
              playRing={() => playRing()}
              setName={setNameAttendance}
              type={type}
              setOnCall={() => acceptCall()}
            />
          ) : null
        )}
      </div>
    );
  }

  const modalOutScreen = () => {
    return (
      <Modal show={outScreenAlert} onHide={() => setOutScreenAlert(false)}>
        <div className="outScreenHeader">
          <h3>Opa!</h3>
          <h3>Vai encerrar o atendimento?</h3>
          <h3>Não esqueça de nos avaliar.</h3>
        </div>
        <div className="outScreenButtons">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setOutScreenAlert(false)}
          >
            Continuar no atendimento
          </button>
          <button
            type="button"
            className="btn btn-warning"
            disabled={disabled}
            onClick={() => {
              setRate(true)
              setOutScreenAlert(false)
            }}
          >
            Encerrar e avaliar atendimento {disabled ? `- ${seconds}s` : ''}
          </button>
        </div>
      </Modal>
    )
  }

  return (
    <div>
      <Header
        balcaoId={balcaoId}
        onClose={() => { setRate(true); } }
        datetime={timer}
        rate={rate}
      />
      {attendantId ? (
        rate ? handleFinish() : renderCall()
      ) : null}
      {modalOutScreen()}
    </div>
  );
}
