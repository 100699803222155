interface Option {
  value: string;
  label: string;
}

export const attendanceReasonData: Option[] = [
  { value: 'IPTU', label: "IPTU" },
  { value: 'ISSQN', label: "ISSQN" },
  { value: 'TMRSU', label: 'TMRSU' },
  { value: 'ITBI', label: "ITBI" },
  { value: 'Multas', label: "Multas" },
  { value: 'Contratos', label: "Contratos" },
  { value: 'Compensação/Restituição', label: "Compensação/Restituição" },
  { value: 'Alteração de titularidade', label: "Alteração de titularidade" },
  { value: 'Negociação existente', label: "Negociação existente" },
  { value: 'Consulta a processo', label: "Consulta a processo" },
  { value: 'Solicitação de transação tributária', label: "Solicitação de transação tributária" },
  { value: 'Refis Simples', label: "Refis Simples" },
  { value: 'Refis Especial', label: "Refis Especial" },
  { value: 'Problema de conexão', label: "Problema de conexão" }
];

export const debtKnowledgeData: Option[] = [
  { value: 'E-mail', label: "E-mail" },
  { value: 'Protesto (Carta)', label: "Protesto (Carta)" },
  { value: 'Protesto (Edital)', label: "Protesto (Edital)" },
  { value: 'Comunicado SEFIN', label: "Comunicado SEFIN" },
  { value: 'Compra e Venda de Imóveis', label: "Compra e Venda de Imóveis" },
  { value: 'Negativação na Praça', label: "Negativação na Praça" },
  { value: 'Iniciativa Própria', label: "Iniciativa Própria" },
  { value: 'Carta TJCE', label: "Carta TJCE" },
  { value: 'Whatsapp', label: "Whatsapp" },
  { value: 'Empresa de cobrança', label: 'Empresa de cobrança' }
];

export const resolutionData: Option[] = [
  { value: 'Abertura de processo', label: "Abertura de processo" },
  { value: 'Agendamento', label: "Agendamento" },
  { value: 'Emissão de Boleto', label: "Emissão de Boleto" },
  { value: 'Informações', label: "Informações" },
  { value: 'Negociação', label: "Negociação" },
];
